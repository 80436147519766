/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'us-east-1',
  aws_cognito_identity_pool_id: 'us-east-1:e3a43f57-52bb-4180-9332-95340a48a342',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_96DJd1OvH',
  aws_user_pools_web_client_id: '7iogdrst70l8avelgtgdgrl7b6',
  oauth: {},
  aws_user_files_s3_bucket: 'doors103432-dev',
  aws_user_files_s3_bucket_region: 'us-east-1',
  aws_dynamodb_all_tables_region: 'us-east-1'
};

export default awsmobile;

export const getObjectUrl = filename => filename && `https://${awsmobile.aws_user_files_s3_bucket}.s3.amazonaws.com/public/${filename}`;
